


#contact {
    padding-top: 160px;
    background-color: #f2f2f2;
  }

.form__service_card {
    max-width: 100%;;
}

.form__data {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 800px) {
    max-width: 500px;
  }
}

.form-group {
  margin-bottom: 20px;
  width: 100%;  
}
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    resize: vertical;
    height: 150px;
  }
  
  .cta-button {
    background-color: rgba(133, 16, 16, 1);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .cta-button:hover {
    background-color: rgba(133, 16, 16, .4);
  }