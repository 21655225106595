.primary-nav {
    padding-top: 10px;

    @media screen and (min-width: 800px) {
        padding-top: 0;
    }
}

.primary-nav ul {

        margin: 0;
        padding: 0;
    }

.primary-nav li {
    list-style: none;
    display: inline-block;
    padding-right: 7px;

    @media screen and (min-width: 800px) {
        padding-right: 20px;
    }
}

.primary-nav li:last-child {
    padding-right: 0;

    @media screen and (min-width: 800px) {
        padding-right: 20px;
    }
}

.primary-nav a {
    color: rgb(255, 255, 255);
    font-weight: 300;
    text-decoration: none;
    padding: 5px 8px;
    font-size: .8rem;
    background-color: rgba(133, 16, 16, .5);

    @media screen and (min-width: 800px) {
        font-size: 1rem;
        padding: 12px 0;
        background-color: transparent;
    }
}

.primary-nav a:hover {
    color: rgba(133, 16, 16, .4);
  }
 