.pageLayout {
    padding-top: 160px;
    text-align: center;
  }
  
  .pageLayout h2 {
    font-size: 3em;
    margin-bottom: 20px;
    color: rgb(133, 16, 16);
  }

  .pageLayout h3 {
    font-size: 2em;
    margin-bottom: 20px;
    color: rgb(133, 16, 16);
  }

  .pageLayout h4 {
    color: rgb(133, 16, 16);
  }
  
   .pageLayout p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #fff;
    margin-bottom: 40px;
  }

  #about.pageLayout p {
    color: #666;
  }  

  .cta-button {
    display: inline-block;
    padding: 12px 25px;
    font-size: 1em;
    background-color: rgba(133, 16, 16, 0.8); 
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 30px;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: rgba(133, 16, 16, 0.4);
  }

  .pageLayout__service_cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .pageLayout__service_card {
    width: 335px;
    margin: 20px;
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 1px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative; 
  }
  
  .pageLayout__service_card h3 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }
  
  .pageLayout__service_card p {
    font-size: 1em;
    line-height: 1.5;
    color: #666;
  }
  

  .serviceImage {
    
    display: block;
    width: 300px;
    height: auto;
    background-color: #1b1818;
    border-radius: 20%;
    margin: 0 auto 20px; 
  }
  