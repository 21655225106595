

   .site-header {
    position: absolute;
    width: 100%;
    z-index: 2;   
    padding: 1rem;
    text-align: center;
    transition: background-color .3s ease-out;
   
    
    @media screen and (min-width: 530px) {    
      background-color: rgba(133, 16, 16, 0.6);
  }
}

.site-header--is-expanded {
  background-color: rgba(133, 16, 16, 0.55);
}

  .site-header__logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    & img {
      max-width: 100%;
      max-height: 150px;
    }
    
    @media screen and (min-width: 800px) {
      left: auto;
      transform: translateX(0);
    }
  }

  .site-header__btn-container {
   
    
    background-color: rgba(133, 16, 16, 1);
       width: max-content;
       margin-right: auto;
       margin-left: auto;
    a {
      color: #fff;
    }

    @media screen and (min-width: 800px) {
      margin: 0;
      order: 2;
    }
  }

  .site-header__menu-icon {
    width: 20px;
    height: 19px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;

    @media screen and (min-width: 800px) {
      display: none;
    }
  }

  .site-header__menu-icon::before {
    content: "";
    position:absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: rgba(133, 16, 16, 1);
    transform-origin: 0 0;
    transition: transform .3s ease-out;
  }

  .site-header__menu-icon__middle {
    position:absolute;
    top: 8;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: rgba(133, 16, 16, 1);
    transition: opacity .3s ease-out;
  }

  .site-header__menu-icon::after {
    content: "";
    position:absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: rgba(133, 16, 16, 1);
    transform-origin: 0 100%;
    transition: transform .3s ease-out;
  }

  .site-header__menu-icon--close-x {
    &::before {
      transform: rotate(45deg) scaleX(1.25);
    }
    
    .site-header__menu-icon__middle {
      opacity: 0;
    }

    &::after {
      transform: rotate(-45deg) scaleX(1.25) translateY(1px);
    }
}
  .site-header__menu-content {
    opacity: 0;
    transform: scale(1.2);
    transition: all .3s ease-out;
    position: relative;
    z-index: -10;
    padding-top: 150px;
    text-align: center;

   @media screen and (min-width: 800px) {
      display: flex;
      justify-content: flex-end;
      opacity: 1;
      z-index: 1;
      padding-top: 0;
      transform: scale(1);
    }
  }

  .site-header__menu-content--is-visible {
    opacity: 1;
    z-index: 1;
    transform: scale(1);
  }
  
.hero img {
  width:  100%;
}



