.row__container {
  display: grid;
  grid-template-columns: 1fr;
  background-color: rgba(133, 16, 16, .5);

  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
}

.home__col1 {
  display: grid;
  justify-content: center;
}

.home__col2 {
  display: grid;
  align-items: center;
  justify-content: center;
}

.hero__homePage-img {
  max-width: 100%;
  height: auto;
}

.headline--small {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}


