.footer {
   justify-content: center;
   padding-top: 8em;
   background-color: rgba(133, 16, 16, 0.8);
   height: 30vh;
   
}

.footer p {
   color: #fff;
   text-align: center;
}
