.headline {
    font-weight: 300;
    font-size: 1.7rem;
    color: #fff;

    @media  screen and (min-width: 800px) {
        font-size: 2.875rem;
    }
}

.headline--small {
    font-weight: 300;
    font-size: 1.2rem;
    color: #ffff;

    @media  screen and (min-width: 800px) {
        font-size: 1.9rem;

    }
}

